@charset "UTF-8";
:root {
	--main_color:black;
	--c_01: #ffcf21;
	--c_01_hsla:43, 89%, 61%;
	--c_02: #1f1e46;
	--c_03: #242c52;
	--c_04: #000;
	--c_06: #f9d157;
	--c_07: #6284c1;
	--c_08: #575757;
	--c_09: #fff03f;
	--c_10: #f7a842;
	--c_12: #c8ae63;
	--c_13: #e41516;
	--c_w: #fff;
	--c_w_hlsa: 0, 0%, 100%;

	--font_size: 18px;
	--line_height: 1.666666666666667rem; /* 30 */
	--font_weight: 400;

	--body_bg:var(--c_w);
	--b_r:5px;

	--h_color:var(--c_02);
	--a:var(--c_13);
	--a_visited:var(--c_12);
	--a_hover:var(--c_06);

	--pagewrap:1070px;
	--pagewrap_sponsors:1920px;
	--content_width:min(calc(100% - var(--window_padding)*2), var(--pagewrap));
	--content_width_sponsors:min(calc(100% - var(--window_padding)*2), var(--pagewrap_sponsors));

	--transition_basic: ease-in-out 0.2s;

	--logo_size:40px;
	--mego_logo_p:10px;
	--mego_logo_bg:var(--c_02);
	--mego_logo_b_r:var(--b_r);

	--lang_border_color:#EBEBEB;
	--lang_b_r:var(--b_r);
	--page_all_bg:var(--c_01);
	
	--top_box_bg:hsla(var(--c_w_hlsa), 0.7);
	--top_box__text_color:var(--c_13);
	
	--left_box_right:45;
	--left_box_left:10;
	--left_box_size:calc(100% + var(--left_box_right) + var(--left_box_left));
	
	--aside_box_content_m_t:80px;

	--rb_text_f_s:1.2rem;
	--rb_text_l_h:1.2;
	--rb_text_f_w:700;
	--rb_text_c:var(--c_w);
	--rb_text_bg_c:var(--c_13);
	--rb_text_m_b:var(--gap_sm);
	--rb_text_p:var(--gap_sm);
	--rb_text_w:min(100%, 450px);
	--rb_box_siaze:max-content;
	--rb_img_siaze:267px;
	--right_content_p:40px;

	--slogan_box_f_s:2.429rem;
	--slogan_box_l_h:120%;
	--slogan_box_bg:var(--c_02);
	--slogan_box_color:var(--c_01);
	--slogan_box_p:0 var(--gap);
	--aside_box_bg:var(--c_w);

	--gap:40px;
	--gap_half:20px;
	--gap_sm:10px;
	--gap_sm_min:5px;
	--window_gap: var(--gap);
	--wp_add: 4;
	--window_padding: calc(20px*var(--wp_add));/* 80px */
	--padding: 20px;

	--res_table__title_f_s:1.143rem;
	--res_table__title_l_h:1.2;
	--res_table_border_c:var(--c_02);
	--res_table__title_c:var(--c_w);
	--res_table__title_bg:var(--c_02);
	--res_table__col_f_s:14px;
	--res_table__col_l_h:1.2;
	--res_table__col_th_c:var(--c_02);
	--res_table__col_th_bg:hsla(var(--c_01_hsla), 0.6);
	--res_table__col_td_hover:hsla(var(--c_01_hsla), 0.3);

	--footer_bg:var(--c_02);
	--footer_text_color:var(--c_01);
	--footer__text_p:var(--gap_sm) var(--gap);

	--input_bg:rgba(255,255,255,0.70);
	--input_border_c:var(--c_01);
	--input_border_r:5px;
	--input_color:var(--c_04);
	--input_hover_border_color:var(--c_07);
	--input_hover_bg:hsla(var(--c_01_hsla), 0.6);
	--input_focus_bg:hsla(var(--c_01_hsla), 0.3);
	--input_placeholder_color:var(--c_08);
	--s_btn_bg:var(--c_03);
	--s_btn_color:var(--c_w);
	--checkbox_b_r:3px;

	--form_popup_bg:rgba(255,255,255,.90);
	--form_popup_color:var(--c_04);
	--form_popup_h_color:#cd1719;

	--menu_top_link:var(--c_02);
	--menu_top_hover_c:var(--c_06);
	--menu_top_bg:var(--c_w);
	--menu_top_hover_bg:var(--c_02);
	--menu_top__ico_bg:var(--c_02);
}