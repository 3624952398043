/* Browse Happy prompt */
.browsehappy {
	display: block;
	margin:0;
	background: #e60003;
	color: #FFF;
	padding: 40px 0;
	text-align:center;
}
.browsehappy a {
	color:#000;
}
.browsehappy a:hover {
	color:#FBA3A4;
}
@supports (display: grid) {
	.browsehappy {
		display: none;
	}
}

/* GENERAL STYLING */
html {
	font-family: Arial, Helvetica, sans-serif, "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed";
	font-size: var(--font_size);
	line-height: var(--line_height);
	font-weight:var(--font_weight);
	color:var(--main_color);
	height: 100%;
	overflow-x: hidden;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	height: -webkit-fill-available;
}
body {
	display: flex;
	flex-direction: column;
	width: 100%;
	/* height: 100%; */
	min-width: 100%;
	min-height: calc(var(--vh, 1vh) * 100);
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: -webkit-fill-available;
	background-color: var(--body_bg);
}
nav ul {
	display: block;
}
nav ul,
:where(ul.form_box) {
	list-style: none;
	list-style-image: none;
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6,
.top_box__text,
.menu_top li a,
.slogan_box__content,
.s_btn {
	font-family: 'Oswald', Arial, Helvetica, sans-serif;
	font-optical-sizing: auto;
	font-weight:700;
}
h1, h2, h3, h4, h5, h6 {
	line-height: 120%;
	color:var(--h_color);
	padding:0 0 20px;
	margin:0;
	text-transform:uppercase;
}
h1 {font-size:2rem;} /* 28px */
h2 {font-size:1.714rem;} /* 24px */
h3 {font-size:1.571rem;} /* 22px */
h4 {font-size:1.429rem;} /* 20px */
h5 {font-size:1.286rem;} /* 18px */
h6 {font-size:1.143rem;} /* 16px */

:where(a):not(.s_btn, .menu_top__ico) { color: var(--a); }
:where(a:visited):not(.s_btn, .menu_top__ico) { color: var(--a_visited); }
:where(a:hover):not(.s_btn, .menu_top__ico) { color: var(--a_hover); }
:where(a:focus):not(.s_btn, .menu_top__ico) { outline: none; }
:where(a:focus-visible):not(.s_btn, .menu_top__ico) { outline-offset:0px; outline: none; }
a:hover, a:active { outline: 0; }

a[href^="tel"] { color: #000; text-decoration:none; }
a * {
	cursor: pointer;
}
picture * {
	width: 100%;
	height: auto;
}

/* Colors for form validity */
input:valid, textarea:valid {  }
input:invalid, textarea:invalid { background-color: #fcc2c2; }

td { vertical-align: top; }

/* GENRAL */
.pagewrap {
	width: var(--content_width);
	margin:0 auto;
}
.pagewrap_sponsors {
	width: var(--content_width_sponsors);
	margin:0 auto;
}
.page_all {
	position: relative;
	padding-top: var(--gap);
	background-color: var(--page_all_bg);
}
/* header */
.top_box {
	position: relative;
	padding-bottom:10px;
	padding-top:10px;
	/* background-color: var(--top_box_bg); */
}
.top_box__wrap {
	position:relative;
}
.top_box__content {
	display:flex;
}
#site_logo {
	white-space: nowrap;
	display: flex;
	align-items: center;
	gap: var(--gap_sm);
}
#site_logo a {
	display: block;
}
.logo {
	width: auto;
	height: var(--logo_size);
}
.mego {
	padding: var(--mego_logo_p);
	background-color: var(--mego_logo_bg);
	border-radius: var(--mego_logo_b_r);
}
.lang_box {
	display: flex;
	align-items:center;
	justify-content: center;
	gap: var(--gap_sm);
	flex:1;
	padding-left: 10px;
	padding-right: 10px;
}
.lang_box a {
	display: block;
	width:30px;
	height:30px;
	border-radius: var(--lang_b_r);
	border:1px solid var(--lang_border_color);
	overflow: hidden;
}
.lang_box img {
	display: block;
	object-fit: fill;	
}
.lang_box a:hover img,
.lang_box a.lang_select img {
	opacity:0.5;
}
.top_box__text {
	color: var(--top_box__text_color);
	font-size:2.143rem;
	line-height:52px;
	text-align:right;
	font-weight:400;
	white-space: nowrap;
}
.header_box {
	text-align:center;
	margin-top: 20px;
	margin-bottom:30px;
}
.all_content {
	padding-bottom:20px;
	display:grid;
	grid-template-columns: 1fr min-content 1fr;
}
.left_box {
	position: relative;
	display: grid;
	grid-auto-rows: min-content;
}
.left_box__img{
	display: block;
	position: relative;
	right: calc(var(--left_box_right)*(-1px));
	left: calc(var(--left_box_left)*(-1px));
	max-width: var(--left_box_size);
}
.left_box__img img {
	display: block;
}
.right_box {
	position: relative;
	z-index: 3;
}
.rb_img {
	justify-self: center;
	display: grid;
	place-items: center;
	position: relative;
	/* width: var(--rb_img_siaze); */
	height: auto;
}
.right_box__text {
	color: var(--rb_text_c);
	background-color: var(--rb_text_bg_c);
	font-size: var(--rb_text_f_s);
	line-height: var(--rb_text_l_h);
	font-weight: var(--rb_text_f_w);
	text-transform: uppercase;
	text-align: center;
	margin-bottom: var(--rb_text_m_b);
	padding: var(--rb_text_p);
	z-index: 4;
}
.article_box {
	padding:30px;
	position: relative;
	z-index: 3;
}
.slogan_box {
	display: grid;
	grid-template-columns: 1fr min-content 1fr;
	position: relative;
	padding:10px 0;
	background-color: var(--slogan_box_bg);
}
.slogan_box__bg {
	background-image: url(../images/svg/line_bg.svg);
	background-repeat: repeat-x;
	background-size: auto 100%;
	background-position: center right;
}
.slogan_box__bg:last-of-type {
	background-position-x: left;
}
.slogan_box__content {
	position: relative;
	color:var(--slogan_box_color);
	background-color: var(--slogan_box_bg);
	font-size:var(--slogan_box_f_s);
	line-height: var(--slogan_box_l_h);
	text-align:center;
	text-transform:uppercase;
	width: max-content;
	max-width: var(--pagewrap);
	margin:0 auto;
	padding: var(--slogan_box_p);
}
.slogan_box__img {
	position: absolute;
	top: 20%;
	left: 100%;
}
/* .add_pic {
	position: absolute;
	top: calc((100% - 30px)*(-1));
	left: 20%;
} */
.popimg,
.sloganimg {
	position: absolute;
	top: 0;
	z-index: 9;
}
.popimg img,
.sloganimg img {
	width: 100%;
	height: auto;
	object-fit: contain;
}
.popimg_tl {
	left: 0;
}
.popimg_tr {
	right: 0;
}
.sloganimg_tl {
	top: -50px;
	left: 40px;
}
.sloganimg_tr {
	top: unset;
	bottom: -60px;
	right: 20px;
}
.aside_box {
	padding:10px var(--gap);
	text-align:center;
	background-color:var(--aside_box_bg);
}
.aside_box__content {
	display: grid;
	grid-template-columns:1fr;
	justify-content: center;
}
.logo_place_big {
	display: flex;
	flex-direction: row;
}
.logo_place {
	display: flex;
	flex-wrap: wrap;
	width: max-content;
	display: grid;
	grid-template-columns: repeat(auto-fill, 153px);
	justify-content: center;
	grid-auto-flow: dense;
	width: 100%;
	padding: 0;
}
.logo_place li {
	display: block;
}
.logo_img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.footer {
	padding:10px 0;
	display: grid;
	grid-template-columns: 1fr min-content 1fr;
	background-color:var(--footer_bg);
}
.footer__text {
	font-size:1.286rem;
	flex:1;
	color: var(--footer_text_color);
	text-align: center;
	padding: var(--footer__text_p);
	width: max-content;
}
.footer__right {
	text-align:right;
}

.res_table {
	width:100%;
	border-collapse:collapse;
}
.res_table th {
	text-align:left;
}
.res_table td,
.res_table th {
	padding:10px;
}
.res_table td,
.res_table th,
.res_table__title th {
	border:1px solid var(--res_table_border_c);
}
.res_table__title th {
	color: var(--res_table__title_c);
	font-size:var(--res_table__title_f_s);
	line-height: var(--res_table__title_l_h);
	font-weight:bold;
	background-color:var(--res_table__title_bg);
}
.res_table__num {
	text-align:right;
	width:1%;
}
.res_table__col .res_table__num {
	text-align:left;
}
.res_table__col th {
	font-size:var(--res_table__col_f_s);
	line-height: var(--res_table__col_l_h);
	font-weight:bold;
	color: var(--res_table__col_th_c);
	background-color:var(--res_table__col_th_bg);
}
.res_table tr:hover td {
	background-color:var(--res_table__col_td_hover);
}

/* form */
input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=email],
select,
textarea {
	background-color:var(--input_bg);
	border:1px solid var(--input_border_c);
	border-radius: var(--input_border_r);
	color:var(--input_color);
	font-size:1rem;
	display: inline-block;
	vertical-align:middle;
}
input[type=text], 
input[type=email],
input[type=password],
input[type=tel],
input[type=email],
select {
	padding:0 10px;
	line-height:40px;
	height:40px;
}
textarea {
	padding:10px;
}
input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover,
input[type=tel]:hover,
input[type=email]:hover,
textarea:hover,
select:hover {
	background-color:var(--input_hover_bg);
	border-color:var(--input_hover_border_color);
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus, 
input[type=tel]:focus,
input[type=email]:focus,
textarea:focus,
select:focus {
	background-color: var(--input_focus_bg);
	outline: none;
}
button { outline:none; }
button:active { outline:none; }
button:focus { outline:none; }

input::-webkit-input-placeholder { color:var(--input_placeholder_color); }
input::-moz-placeholder { color:var(--input_placeholder_color); } /* firefox 19+ */
input:-ms-input-placeholder { color:var(--input_placeholder_color); } /* ie */
input.hasPlaceholder { color:var(--input_placeholder_color); }
input:-moz-placeholder { color:var(--input_placeholder_color); }

.s_btn,
.s_btn:visited {
	background-color:var(--s_btn_bg);
	color:var(--s_btn_color);
	border: 0px none;
	border-radius: var(--input_border_r);
	margin:0;
	padding:4px 10px 8px;
	font-size:1.714285714285714rem;
	line-height:2rem;
	width:100%;
	text-decoration:none;
	text-transform:uppercase;
	vertical-align:middle;
	text-align:center;
	display: block;
	cursor: pointer;
}
.s_btn:hover,
.s_btn:focus {
	--s_btn_bg:var(--c_01);
	--s_btn_color:var(--c_w);
	text-decoration:none;
}
.form {
	position:relative;
}
.form ul {
	padding: 0;
}
.form li {
	margin: 0 0 10px 0;
	padding: 0;
	width:100%;
	position: relative;
	vertical-align: top;
	display:block;
}
.form li.form_check {
	margin: 0 0 5px 0;
}
.form label {
	width:100%;
	display:block;
	padding:0 0 2px;
	cursor:pointer;
}
.form textarea {
	resize:vertical;
	width:100%;
}
.form label,
.form li > input[type=text],
.form li > input[type=email],
.form li > input[type=password],
.form li > input[type=tel],
.form li > input[type=email],
.form li > textarea,
.form li > select {
	display:block;
	width:100%;
}
.lt-ie8 .form li > input[type=text],
.lt-ie8 .form li > input[type=email],
.lt-ie8 .form li > input[type=password],
.lt-ie8 .form li > input[type=tel],
.lt-ie8 .form li > input[type=email],
.lt-ie8 .form li > textarea,
.lt-ie8 .form li > select {
	width:96%;
}
.form  label > span {
	color:#e60003;
	margin-right:3px;
}
.form li.form_btn {
	padding-top:10px;
	margin-bottom:0;
}
.form_btn .s_btn {
	width:100%;
}
input[type=text].failed,
input[type=email].failed,
input[type=password].failed,
input[type=tel].failed,
input[type=email].failed,
select.failed,
textarea.failed,
input.failed {
	background-color:#f49f98;
}

.form__checkbox {
	font-size:0;
	padding:0;
	width:21px;
	height:21px;
	margin-right:10px;
	vertical-align:middle;
	display: inline-block;
}
.form__checkbox input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.lt-ie9 .form__checkbox input {
	clip:rect(auto, auto, auto, auto);
	height:auto;
	margin:auto;
	overflow:auto;
	position:relative;
	width:auto;
}
.form__checkbox_value {
	background:url(../images/checkbox.png?2) no-repeat;
	display: inline-block;
	height:21px;
	width:21px;
	border: 1px solid var(--input_border_c);
	border-radius: var(--checkbox_b_r);
	position: relative;
	vertical-align:middle;
}
.lt-ie9 .form__checkbox_value {
	display:none;
}
.form__checkbox input:checked + .form__checkbox_value {
	background:url(../images/checkbox_on.png?2) no-repeat;
}

.form_popup {
	width:90%;
	top:50%;
	left:0;
	margin-left:5%;
	transform:translateY(-50%);
	padding:40px 20px;
	position:absolute;
	text-align:center;
	background-color:var(--form_popup_bg);
	z-index:99;
	box-shadow: 0 0 62px 0 rgba(35,38,41,0.30);
	display:none;
}
.form_popup__visible {
	display:block;
}
.form_popup > div {
	display: inline-block;
	vertical-align: middle;
	font-size:1rem;
	color:var(--form_popup_color);
}
.form_popup h2 {
	margin-bottom:10px;
	font-size:20px;
	line-height:23px;
	color:var(--form_popup_h_color);
	font-weight:bold;
	text-transform:uppercase;
}
.form_popup:before {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	content: "";
}
.form_popup > a {
	display:block;
	position:absolute;
	font-size:18px;
	line-height:18px;
	width:18px;
	height:18px;
	top:10px;
	right:10px;
	color:var(--form_popup_color);
	text-decoration:none;
	font-weight:bold;
}
.form_popup > a:hover {
	--form_popup_color:#ff0000;
}

/* coocie */
.coocie_popup {
	width:100%;
	top:0;
	left:0;
 	padding:40px 60px 40px 40px;
	position:fixed;
	background-color:var(--form_popup_bg);
	z-index:9999;
	display:none;
}
.coocie__visible {
	display:block;
}
.coocie_popup > div {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	vertical-align: middle;
	font-size:1rem;
	color:var(--form_popup_color);
}
.coocie_popup p {
	padding-top: 5px;
}
.coocie_popup .s_btn {
	width: auto;
	font-size: 1rem;
	line-height: 30px;
	height:auto;
	padding:0 10px;
	margin-left: 40px;
}
.coocie_popup .s_btn:after {
	width: 20px;
	right: -20px;
}

/* top menu */
.menu_top {
	position:relative;
	z-index:999;
}
.menu_top li {
	display: inline-block;
	position:relative;
	vertical-align:top;
	margin-right:20px;
	border-radius: var(--input_border_r);
	transition:all var(--transition_basic);
}
.menu_top li:last-child {
	margin-right:0;
}
.menu_top li a,
.menu_top li a:visited {
	display:block;
	font-size:2rem;
	line-height:45px;
	padding:0 10px;
	text-transform:uppercase;
	color:var(--menu_top_link);
	text-decoration: none;
	border-radius: var(--input_border_r);
	transition:all var(--transition_basic);
}
.menu_top li a:hover,
.menu_top li a:focus,
.menu_top li.active a,
.menu_top li.active a:hover {
	--menu_top_link:var(--menu_top_hover_c);
	background: linear-gradient(180deg, rgba(254,251,242,1) 0%, rgba(254,230,160,1) 20%, rgba(249,209,87,1) 50%, rgba(177,117,21,1) 50%, rgba(237,184,78,1) 70%, rgba(250,197,91,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.menu_top li:has(a:hover),
.menu_top li:has(a:focus),
.menu_top li.active,
.menu_top li.active:has(a:hover) {
	background-color:var(--menu_top_hover_bg);
	border-radius: var(--input_border_r);
}

.menu_top__ico {
	position: relative;
	height:30px;
	width:40px;
	margin-top: 12px;
	margin-left: 20px;
	overflow: hidden;
	white-space: nowrap;
	color: transparent;
	display:none;
	z-index:999;
}
.menu_top__ico span {
	position: absolute;
	height: 4px;
	width: 40px;
	top: 50%;
	margin-top: -2px;
	left: 50%;
	right:0;
	margin-left: -20px;
	background-color: var(--menu_top__ico_bg);
}
.menu_top__ico span::before,
.menu_top__ico span::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left:0;
	background-color: var(--menu_top__ico_bg);
}
.menu_top__ico span::before {
	top:-10px;
}
.menu_top__ico span::after {
	top:10px;
}
.menu_top__ico:hover span,
.menu_top__ico:hover span::before,
.menu_top__ico:hover span::after {
	--menu_top__ico_bg:var(--c_13);
}

.left_add_pic_top {
	position: absolute;
	left: 0;
	top:45px;
}
.right_add_pic_top {
	position: absolute;
	right:0;
	top: 100%;
}
.left_add_pic_bot {
	position: absolute;
	left: 0;
	bottom: 100%;
}
.right_add_pic_bot {
	position: absolute;
	right: 0;
	bottom: 50%;
	transform: translateY(50%);
}

/* Clearfix: contain floats */
.clearfix:before,
.clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}
.clearfix:after,
.color_box:after {
	clear: both;
}