@charset "UTF-8";
*,
*::before,
*::after {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
	*,
*::before,
*::after {
	-webkit-animation-delay: -1ms;
			animation-delay: -1ms;
	-webkit-animation-duration: 1ms;
			animation-duration: 1ms;
	-webkit-animation-iteration-count: 1;
			animation-iteration-count: 1;
	background-attachment: initial;
	scroll-behavior: auto;
	transition-delay: 0s;
	transition-duration: 0s;
	}
}

:where(html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video) {
	/* cursor: default; */
	-moz-tab-size: 4;
	-o-tab-size: 4;
		 tab-size: 4;
	margin: 0;
	padding: 0;
	border: none;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

html {
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: inherit;
	background-color: inherit;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0;
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
	overflow: visible;
}
hr:not([size]) {
	height: 1px;
}

:where(h1,
h2,
h3,
h4,
h5,
h6) {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 500;
	line-height: 1.2;
}

h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	h1 {
	font-size: 2.5rem;
	}
}

h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	h2 {
	font-size: 2rem;
	}
}

h3 {
	font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
	h3 {
	font-size: 1.75rem;
	}
}

h4 {
	font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
	h4 {
	font-size: 1.5rem;
	}
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	/* margin-bottom: 1rem; */
}

abbr[title],
abbr[data-bs-original-title] {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

:where(ol,
ul) {
	padding-left: 2rem;
}

:where(ol,
ul,
dl) {
	margin-top: 0;
	margin-bottom: 1rem;
}

:where(ol ol,
ul ul,
ol ul,
ul ol) {
	margin-bottom: 0;
}

nav li {
	list-style: none;
	padding: 0;
}
/* nav li::before {
	content: "​";
} */

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bold;
}

small {
	font-size: 0.875em;
}

mark {
	padding: 0.2em;
	background-color: currentColor;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

:where(a) {
	cursor: pointer;
	color: #3333e1;
	background-color: transparent;
	text-decoration: none;
}
:where(a:hover) {
	color: #3434e0;
	text-decoration: underline;
}
:where(a:focus) {
	outline: thin dotted;
}
a:not([href]):not(class), a:not([href]):not([class]):hover {
	cursor: default;
	color: inherit;
	text-decoration: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	touch-action: manipulation;
}

pre,
code,
kbd,
samp {
	font-family: "Fira Code", "Cascadia Code", Consolas, "Inconsolata", Monaco, Menlo, "Noto Mono", "Roboto Mono", "Droid Sans Mono", "Ubuntu Mono", "Ubuntu Monospace", "Source Code Pro", "Oxygen Mono", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1.05em;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: 0.875em;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}
pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-size: 0.875em;
	color: inherit;
	word-wrap: break-word;
}

a > * {
	color: inherit;
	cursor: pointer;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: inherit;
	background-color: inherit;
	border-radius: 0.2rem;
}
kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

figure {
	margin: 0 0 1rem;
}

iframe,
img,
input,
select,
textarea {
	height: auto;
	max-width: 100%;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

svg:not([fill]) {
	fill: currentColor;
}
svg:not(:root) {
	overflow: hidden;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
	text-indent: 0;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: inherit;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

label {
	display: inline-block;
}

:where(button) {
	border-radius: 0;
	text-transform: none;
}
:where(button:focus) {
	outline: dotted 1px;
	outline: -webkit-focus-ring-color auto 5px;
}

:where(input,
button) {
	overflow: visible;
}

:where(input,
button,
select,
optgroup,
textarea) {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

:where(button,
input,
select,
textarea) {
	background-color: transparent;
	border: 1px solid WindowFrame;
	color: inherit;
	font: inherit;
	letter-spacing: inherit;
	padding: 0.25em 0.375em;
}

:where(button,
select) {
	text-transform: none;
}

[role=button] {
	cursor: pointer;
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	text-transform: none;
	word-wrap: normal;
}
select:not([multiple]):not([size]) {
	background-image: "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E";
}

::-ms-expand {
	display: none;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
	-webkit-appearance: button;
}
button:not(disabled),
[type=button]:not(disabled),
[type=reset]:not(disabled),
[type=submit]:not(disabled) {
	cursor: pointer;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

textarea {
	overflow: auto;
	-ms-overflow-style: scrollbar;
	resize: vertical;
	vertical-align: top;
}

fieldset {
	min-width: 0;
	padding: 0.35em 0.75em 0.625em;
	margin: 0;
	border: 1px solid inherit;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
	max-width: 100%;
	white-space: normal;
}
@media (max-width: 1200px) {
	legend {
	font-size: 1.5rem;
	}
}
legend + * {
	clear: left;
}

dialog {
	background-color: inherit;
	border: solid;
	color: inherit;
	display: block;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	left: 0;
	margin: auto;
	padding: 1em;
	position: absolute;
	right: 0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
dialog:not([open]) {
	display: none;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto;
}

::-webkit-input-placeholder {
	color: inherit;
	opacity: 0.54;
}

[type=search] {
	outline-offset: -2px;
	/* -webkit-appearance: none;
	-moz-appearance: none;
	box-sizing: content-box; */
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
	-webkit-appearance: none;
	-moz-appearance: none;
}

[type=tel],
[type=url],
[type=email],
[type=number] {
	direction: ltr;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

::-moz-ui-invalid {
	box-shadow: none;
}

::-moz-focusring {
	outline: 1px dotted ButtonText;
}

output {
	display: inline-block;
}

iframe {
	border: none;
}

audio,
canvas,
video {
	display: inline;
	display: inline-block;
	zoom: 1;
	max-width: 100%;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	display: inline-block;
	vertical-align: baseline;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

[aria-busy=true] {
	cursor: progress;
}

[aria-controls] {
	cursor: pointer;
}

[aria-disabled=true],
[disabled] {
	cursor: not-allowed;
}

[aria-hidden=false][hidden=false] {
	display: initial;
}
[aria-hidden=false][hidden=false]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	position: absolute;
}
